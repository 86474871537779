import { CalloutCard, Card, Frame, Layout, Loading, Page } from '@shopify/polaris';
import React, { useState, useEffect} from 'react';
import Uploader from '../components/uploader';
import List from '../components/list';
import { graphql } from '../utils/session';

const Home = () => {
  const [graphqlData, setGraphqlData] = useState(null as any);
  useEffect(() => {
    graphql(`{
      shop {
        name
      }
      app {
        title
        installation {
          accessScopes {
            description
            handle
          }
        }
      }
    }`).then(data => setGraphqlData(data));
  }, [])
  return (
    {graphqlData} ? (
      <Page>
        <Layout>
        <Layout.Section>
            <Uploader />
          </Layout.Section>
          <Layout.Section>
            <List />
          </Layout.Section>
        </Layout>
      </Page>
    ): (
      <div style={{height: '100px'}}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )
  )
  return  <CalloutCard
  title="Customize the style of your checkout"
  illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
  primaryAction={{
    content: 'Customize checkout',
    url: 'https://www.shopify.com',
  }}
>
  <p>Upload your store’s logo, change colors and fonts, and more.</p>
</CalloutCard>
}

export default Home;