import { Button, Card, IndexTable, Modal, TextContainer, TextStyle } from '@shopify/polaris';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { graphql, restApi } from '../utils/session';
const List = () => {
  const [metafields, setMetafields] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentLogs, setCurrentLogs] = useState<string>('');
  const interval = useRef<any>(null);
  const loadMetafields = useCallback(async () => {
    const gqlQuery = `{
      shop {
        metafields(namespace:"pact_markdowns_logs" reverse:true first:250){
          edges {
            node {
              value
              created_at: createdAt
              id: legacyResourceId
              key
            }
          }
        }
      }
    }`
    const response = await graphql(gqlQuery);
    setMetafields(response.data?.shop?.metafields?.edges?.map?.((edge: any) => edge.node));
    setIsLoading(false);
  }, [])
  useEffect(() => {
    setIsLoading(true);
    loadMetafields();
    interval.current = setInterval(loadMetafields, 30000);
    return () => clearInterval(interval.current);
  }, [])
  const rowMarkup = metafields.map(({ id, key, value, created_at }, index) => {
    let data: any = {};
    try {
      data = JSON.parse(value);
    } catch (e) {
      console.error(e);
    }
    let rows = []
    rows.push('STATUS: ' + data.status + ' PROCESSED: ' + data.processed + ' TOTAL: ' + data.total);
    rows.push('ERRORS:');
    (data.errors || []).forEach((error:any) => rows.push(error.message ? `[${error.sku}] ${error.message}` : error))
    if (!data.errors || !data.errors.length) {
      rows.push('None');
    }
    rows.push('');
    rows.push('LOGS');
    (data.log || []).forEach((log:any) => rows.push('[' + log.s + '] ' + (log.v ? 'success' : 'error')));
    const logs = rows.join('\n');
    return (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <TextStyle variation="strong">{(new Date(data.run_at || created_at)).toLocaleString()}</TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>{data.status}</IndexTable.Cell>
        <IndexTable.Cell>{data.processed}</IndexTable.Cell>
        <IndexTable.Cell>{data.total}</IndexTable.Cell>
        <IndexTable.Cell>
          <Button size='slim' onClick={() => {
            setCurrentLogs(logs);
            setModalIsOpen(true);
          }}>
            View logs
          </Button>
          <Button size="slim" onClick={async () => {
            const metafieldData = await restApi(`/metafields.json?namespace=pact_markdowns&key=${key}&limit=1`);
            const metafield = metafieldData?.metafields?.[0];
            if (metafield) {
              const markdown = JSON.parse(metafield.value);
              const csvData = [['SKU', 'Price', 'Compare at Price']]
              markdown.forEach((item:any) => {
                csvData.push([`"${item.sku}"`, item.price, item.compare_at_price]);
              });
              const csvString = csvData.map((row:any) => row.join(',')).join('\n');
              const element = document.createElement('a');
              element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
              element.target = '_blank';
              element.download = `original_markdown_${key}.csv`;
              element.click();
            }
          }}>
            Download original
          </Button>
          {data.status === 'scheduled' ? (
            <Button 
              size='slim'
              onClick={async () => {
                if (window.confirm('Are you sure?')) {
                  setIsLoading(true);
                  await restApi(`/metafields/${id}.json`, {
                    metafield: {
                      id,
                      value: JSON.stringify({...data, status: 'cancelled'}),
                      type: 'json'
                    }
                  }, 'PUT')
                  loadMetafields();
                }
              }}
              destructive
              >
                cancel
            </Button>

          ) : (data.status !== 'cancelled' ? 
            <Button size="slim" onClick={async () => {
              const csvData = [['SKU', 'Price', 'Compare at Price']]
              data.log.forEach((item:any) => {
                csvData.push([`"${item.s}"`, item.p, item.c]);
              });
              const csvString = csvData.map((row:any) => row.join(',')).join('\n');
              const element = document.createElement('a');
              element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
              element.target = '_blank';
              element.download = `reversal_markdown_${key}.csv`;
              element.click();
            }}>
              Download reversal
            </Button> : null
          )}
        </IndexTable.Cell>
      </IndexTable.Row>
      );
  });

  return (
  <>
    <Card>
      <IndexTable resourceName={{singular: "Markdown", plural: "Markdowns"}} itemCount={metafields.length} headings={[{title: 'Date'}, {title: 'Status'}, {title: 'Processed count'}, {title: 'Total count'}, {title: 'Actions'}]} selectable={false} loading={isLoading}>
        {rowMarkup}
      </IndexTable>
    </Card>
    <Modal
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      title="Markdown Process Logs"
    >
      <Modal.Section>
        <TextContainer>
          <pre>{currentLogs}</pre>
        </TextContainer>
      </Modal.Section>
    </Modal>
  </>
  )
}

export default List;