import { Banner, Card, ChoiceList, DataTable, DatePicker, DropZone, List, TextContainer, TextField } from '@shopify/polaris';
import React, { useCallback, useMemo, useState } from 'react';
import Papa from 'papaparse';
import { expandedRestApi } from '../utils/session';

const parseFile = (file: File, config: any = {}): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (event) => {
      const fileContents:string = (event.target?.result || '') as string;
      Papa.parse(fileContents, {
        ...config,
        error: reject,
        complete: resolve
      })
      resolve(fileContents)
    }
    reader.onerror = reject;
  })
}
const today = new Date();
today.setHours(today.getHours() + 2);
const startDateTime = today.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':');

const Uploader = () => {
  const [markdowns, setMarkdowns] = useState<Markdown[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const disableDatesBefore = new Date();
  disableDatesBefore.setDate(disableDatesBefore.getDate() - 1);
  
  const [selectedTime, setSelectedTime] = useState<string>(startDateTime);
  const [markdownRuntimeOption, setMarkdownRuntimeOption] = useState<('now'|'later')[]>(['now']);
  const [{month, year}, setDate] = useState({month: today.getMonth(), year: today.getFullYear()});
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const selectedDate = useMemo(() => {
    const date = new Date(selectedDates.start);
    date.setHours(parseInt(selectedTime.split(':')[0]));
    date.setMinutes(parseInt(selectedTime.split(':')[1]), 0, 0);
    return date;
  }, [selectedDates, selectedTime])
  const handleMonthChange = useCallback(
    (month, year) => setDate({month, year}),
    [],
  );
  const handleDropZoneDrop = useCallback(async (_dropFiles, acceptedFiles, _rejectedFiles) => {
    setIsLoading(true);
    let localErrors: string[] = [];
    let localMarkdowns: Markdown[] = [];

    for(const file of acceptedFiles) {
      const markdownConfiguration = await parseFile(file, {
        header: true,
        transformHeader: (header: string) => `${header}`.toLocaleLowerCase().trim().replaceAll(' ', '_')
      });
      localErrors = [...localErrors, ...(markdownConfiguration.errors || []).map((e:any) => `[${file.name}] Row #${e.row} ${e.message}`)];

      // eslint-disable-next-line no-loop-func
      (markdownConfiguration.data || []).forEach((data: any, index: number) => {
        const currentMarkdown:Markdown = {
          sku: `${data.sku}`.trim(),
          price: parseFloat(`${data.price}`.trim()),
          compare_at_price: parseFloat(`${data.compare_at_price}`.trim()),
        }
        if (!`${data.compare_at_price}`.trim()) {
          currentMarkdown.compare_at_price = undefined;
        }

        if (!data.sku) {
          localErrors.push(`[${file.name}] Row #${index + 1} Missing SKU`);
          return
        }

        if (typeof data.price === 'undefined') {
          localErrors.push(`[${file.name}] Row #${index + 1} (${currentMarkdown.sku} Missing price`);
          return
        }

        if (currentMarkdown.compare_at_price && currentMarkdown.price > currentMarkdown.compare_at_price) {
          localErrors.push(`[${file.name}] Row #${index + 1} (${currentMarkdown.sku}) Price value cannot be higher than compare at price value.`);
          return;
        }
        localMarkdowns = [...localMarkdowns, currentMarkdown];
      });
    }
    setMarkdowns([...markdowns, ...localMarkdowns])
    setErrors([...localErrors])
    setIsLoading(false);
  }, [markdowns]);

  const handleProcessFile = useCallback(async () => {
    setIsLoading(true);
    await expandedRestApi('/processMarkdowns', {
      markdowns,
      type: markdownRuntimeOption[0],
      run_at: selectedDate.toISOString(),
    });
    setMarkdowns([]);
    setIsLoading(false);
    return;
  }, [markdowns, markdownRuntimeOption, selectedDate]);

  return (
    <Card
      title="Upload a markdown file"
      primaryFooterAction={{content: 'Process files', loading: isLoading, disabled: !!!markdowns.length, onAction: handleProcessFile}}
      secondaryFooterActions={[{content: 'Clear files', disabled: !!!markdowns.length, onAction: () => setMarkdowns([])}]}
    >
      
      <Card.Section title="Upload markdown files">
        <TextContainer>
          {errors && errors.length ? (
            <Banner title="Some items did not process" status="critical">
              <List type="bullet">
                {errors.map(e => <List.Item key={e}>{e}</List.Item>)}
              </List>
            </Banner>
          ) : null}
        </TextContainer>
        
        {markdowns && markdowns.length ? (
            <DataTable
              columnContentTypes={[
                'text',
                'text',
                'text'
              ]}
              headings={['SKU', 'Price', 'Compare at price']}
              rows={markdowns.map((markdown:Markdown) => [markdown.sku, `$${markdown.price}`, markdown.compare_at_price ? `$${markdown.compare_at_price}` : ''])}
            />
        ) : null}

        <DropZone allowMultiple onDrop={handleDropZoneDrop}>
          <DropZone.FileUpload />
        </DropZone>
      </Card.Section>

      <Card.Section title="Choose when this markdown will run">
      <ChoiceList
        title="When should this markdown run?"
        choices={[
          {label: 'Now', value: 'now'},
          {label: 'At a later date', value: 'later'}
        ]}
        selected={markdownRuntimeOption}
        onChange={(newValue) => setMarkdownRuntimeOption(newValue as any)}
      />
      {markdownRuntimeOption[0] === 'later' ? (
        <>
          <DatePicker
            disableDatesBefore={disableDatesBefore}
            month={month}
            year={year}
            onChange={setSelectedDates}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
          />
          <TextField
            label={`Time (${(Intl.DateTimeFormat().resolvedOptions().timeZone)}, GMT${new Date().getTimezoneOffset() / 60 * -1})`}
            value={selectedTime}
            onChange={(newValue) => setSelectedTime(newValue)}
            autoComplete="off"
            error={selectedDate < new Date() ? 'The date and time chosen must be at least an hour into the future.' : undefined}
            type='time'
          />
        </>
      ) : null}

      </Card.Section>
    </Card>
  )
}

type Markdown = {
  sku: string;
  price: number;
  compare_at_price?: number;
}

export default Uploader;